import request from "@/utils/request"
/**
 * 获取report列表
 * @param {*} params 
 * @returns 
 */
export const getList = function (params) {
  return request({
    url: "/SimpleFinalExcel/excelList",
    method: "get",
    params
  });
}
//弃用
export const changeSatus = function (params) {
  return request({
    url: "/SimpleModelExcel/exStatus",
    method: "get",
    params
  });
}
/**
 * 获取全部列表
 * @returns 
 */
export const getAllList = function () {
  return request({
    url: "/SimpleModelExcel/reExcelList",
    method: "get"
  });
}
/**
 * 获取报告模板列表
 * @returns 
 */
export const getMbList = function () {
  return request({
    url: "/CreateMexcel/list ",
    method: "get"
  });
}

/**
 * 删除
 * @param {} params 
 * @returns 
 */
export const delExcel = function (params) {
  return request({
    url: "/SimpleFinalExcel/delExcel",
    method: "POST",
    data: params
  });
}
/**
 * 获取report类型
 * @param {} param 
 * @returns 
 */
export const getReportType = function (param) {
  return new Promise(resolve => {
    resolve([{
      label: "校验单",
      value: "1"
    }, {
      label: "校准证书",
      value: "2"
    }]);
  });
}

/**
 * 创建excel
 * @param {} params 
 * @returns 
 */
export const creatMexcel = function (params) {
  return request({
    url: "/CreateMexcel/creatMexcel",
    method: "GET",
    params
  });
}