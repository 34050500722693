<template>
  <div class="report-create-box">
    <div class="header">
      <view-card title="创建报告模板">
        <steps :active="steps_current" :steps="steps_option" slot="content"></steps>
      </view-card>
    </div>
    <div class="operate-content">
      <template v-if="steps_current == 1">
        <view-card title="选择报告类型">
          <div class="type—select-box" slot="content">
            <div class="conetnt">
              <div class="select-box">
                <span class="_text">请选择报告类型</span>
                <el-select v-model="reportType" placeholder="请选择报告类型">
                  <el-option v-for="item in reportTypeList" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </div>
              <div class="select-box">
                <span class="_text">选择对应的记录本模板</span>
                <el-select v-model="recordSelected" ref="recordModel" placeholder="请选择记录本模板">
                  <el-option v-for="item in recordList" :key="item.excelId" :label="item.fileName"
                    :value="`${item.fileDir}`">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="button-box-2" style="width: 100%">
              <button class="btn-big primary-button" @click="next">
                下一步
              </button>
            </div>
          </div>
        </view-card>
      </template>
      <template v-else-if="steps_current == 2">
        <view-card title="编辑模板正文">
          <div class="button-box-2_" style="width: 100%" slot="content">
            <div @click="editorWord"
              style="cursor: pointer;display: flex;flex-direction: column;row-gap: 8px;align-items: center;">
              <svg-icon icon-class="editor" :size="5"></svg-icon>
              <div><span>{{ "点击编辑模板正文" }}</span></div>
            </div>
            <div class="button-box">
              <button class="btn-big primary-button" @click="steps_current--">
                上一步
              </button>
              <button class="btn-big primary-button" @click="goReportPage">
                {{ "确定" }}
              </button>
            </div>
          </div>
        </view-card>
      </template>
    </div>
  </div>
</template>

<script>
import steps from "@/components/Steps";
import { getAllList, getReportType, creatMexcel, getMbList } from "@/api/report";
import { Message, Loading } from "element-ui";
import _ from "loadsh"
const STEPS_OPTION = [
  {
    title: "第 1 步",
    description: "选择报告类型",
  },
  {
    title: "第 2 步",
    description: "编辑模板正文",
  }
]; //步骤条配置
export default {
  name: "report_create",
  components: {
    steps,
  },
  data() {
    return {
      steps_option: STEPS_OPTION,
      steps_current: 1,
      wordName: "",
      reportType: "",
      reportTypeList: [],
      reportTemplateList: [],
      reportTemplate: [],
      recordList: [],
      recordSelected: "",
      fileDir: "",
      headers: "",
      fileList: [],
    };
  },
  computed: {
    uploadData: function () {
      return {
        type: this.reportType
      }
    }
  },
  methods: {
    Fullscreen(refName) {
      let dom = this.$refs[refName];
      dom.requestFullscreen();
    },
    getReportTypeList() {
      getReportType().then((res) => {
        this.reportTypeList = res;
      });
    },
    getRecordList() {
      getAllList()
        .then((res) => {
          this.recordList = res.data;
        })
        .catch((e) => { });
    },
    getRecordTemplateList() {
      getMbList()
        .then((res) => {
          this.reportTemplateList = res.data;
        })
        .catch((e) => { });
    },
    next() {
      if (_.isEmpty(this.recordSelected) || !this.reportType) {
        Message({
          message: "请选择记录本模板或报告模板",
          type: "warning",
          duration: 5 * 1000,
        });
        return;
      }
      this.steps_current++;
      this.openCreateWord();
    },
    openCreateWord() {
      console.log(111);
      const loading = Loading.service({
        text: "数据加载中",
        target: ".app-main-view"
      });
      creatMexcel({
        fileDir: this.recordSelected,
        type: this.reportType
      }).then(res => {
        this.fileDir = res;
        let params = JSON.stringify({
          fileDir: res
        });
        POBrowser.openWindow(
          "/PgOffice/reportCreateExcel",
          "width=980px;height=800px;",
          params
        );
      }).catch(e => {
        Message({
          message: "接口异常",
          type: "waring",
          duration: 2 * 1000,
        });
      }).finally(() => {
        loading.close();
      });
    },
    editorWord() {
      let params = JSON.stringify({
        zfileDir: this.fileDir
      });
      POBrowser.openWindow(
        "/PgOffice/reportEditor",
        "width=980px;height=800px;",
        params
      );
    },
    goReportPage() {
      this.$router.push("/reportManagement");
    }
  },
  created() {
    this.getReportTypeList();
    this.getRecordList();
    // this.getRecordTemplateList();
    this.headers = {
      Authorization: "Bearer " + this.$store.getters.token,
    };
    window.PageOfficeCbFn = function () { };
  },
};
</script>

<style lang="less">
.report-create-box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  gap: 20px;

  .header {
    width: 100%;
    height: 30%;
  }

  .operate-content {
    width: 100%;
    flex: 1;

    .type—select-box {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: column;
      gap: 10%;
      padding: 10px;
      box-sizing: border-box;

      .conetnt {
        flex-grow: 1;
        display: flex;
        align-items: center;
        flex-direction: column;
        row-gap: 20px;
        justify-content: center;

        .select-box {
          display: flex;
          align-items: center;
          width: 800px;

          ._text {
            width: 200px;
            display: block;
            text-align: left;
          }
        }


        .el-select {
          width: 400px;

          .el-input {
            width: 400px;
            height: 50px;
          }

          .el-input__suffix {
            margin: 0;

            .el-input__suffix-inner {
              margin: 0;
            }
          }
        }

        span {
          color: #101010;
          font-size: 16px;
          font-weight: 600;
          margin-right: 32px;
        }
      }

      .button-box {
        height: 200px;
        display: flex;
        align-items: center;
      }
    }
  }

  .button-box {
    gap: 30px;
    display: flex;
  }


  .button-box-2 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
  }
}

.button-box-2_ {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.editor-btn {
  background-color: var(--blue);
  height: 40px;
  width: 120px;
}

.uploader {
  display: flex;
  gap: 10px;
  height: 40px;
  align-items: center;
  position: relative;
  width: 450px;

  .el-upload--text {
    position: absolute;
    right: 0;
  }

  .el-upload-list {
    width: 400px;
    border: 1px solid #c0c4cc;
    height: 36px;
    display: flex;
    align-items: center;
    flex-flow: column;
    justify-content: center;
    border-radius: 4px;

    .el-upload-list__item:first-child {
      margin: 0;
    }
  }
}
</style>
